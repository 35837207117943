import "./App.css";
import { useState } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import linkdinLogo from "./Icons/icons8-linkedin-48.png";
import coffeLogo from "./Icons/icons8-coffee-52.png";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function App() {
  let [loading] = useState(true);
  let [color] = useState("#ffc900");
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <ClimbingBoxLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={32}
        />
        <p></p>
        <p> My Info page is Almost there ... </p>
        <p>
          Just need Some
          <img alt={"ds"} src={coffeLogo} /> ...
        </p>
        <p>
          <a href="https://www.linkedin.com/in/ohabash">
            <img alt={"ds"} src={linkdinLogo} />
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
